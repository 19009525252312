import {
	SENDPARTNERDATA,
	SENDPARTNERDATASUCCESS,
	SENDPARTNERDATAFAILURE,
	ClearStateAction,
	CLEARSTATE,
	SendPartnerDataAction,
	SendPartnerDataSuccessAction,
	SendPartnerDataFailureAction,
	SaveImagesAction,
	SAVEIMAGES,
	SaveImagesSuccessAction,
	SAVEIMAGESSUCCESS,
	SaveImagesFailureAction,
	SAVEIMAGESFAILURE
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const SendPartnerData = (payload: any, meta?: any): SendPartnerDataAction => {
	return {
		type: SENDPARTNERDATA,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const SendPartnerDataSuccess = (payload: any, meta?: any): SendPartnerDataSuccessAction => {
	return {
		type: SENDPARTNERDATASUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const SendPartnerDataFailure = (payload: any, meta?: any): SendPartnerDataFailureAction => {
	return {
		type: SENDPARTNERDATAFAILURE,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const SaveImages = (payload: any, meta?: any): SaveImagesAction => {
	return {
		type: SAVEIMAGES,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const SaveImagesSuccess = (payload: any, meta?: any): SaveImagesSuccessAction => {
	return {
		type: SAVEIMAGESSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const SaveImagesFailure = (payload: any, meta?: any): SaveImagesFailureAction => {
	return {
		type: SAVEIMAGESFAILURE,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ClearState = (payload: any, meta?: any): ClearStateAction => {
	return {
		type: CLEARSTATE,
		payload,
		meta
	};
};
