import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { ActionState } from '../../constants/api';

export enum RichContentAction {
	GETRICHCONTENTLIST = 'GETRICHCONTENTLIST',
	GETRICHCONTENTLISTFAILURE = 'GETRICHCONTENTLISTFAILURE',
	GETRICHCONTENTLISTSUCCESS = 'GETRICHCONTENTLISTSUCCESS'
}
interface AdditionalData {
	additionalData: Record<string, any>;
}
export interface FileInfo extends AdditionalData {
	fileId: string;
	fileName: string;
	fileSequence: number;
	isPublished: boolean;
}
export interface RichContent extends AdditionalData {
	title: string;
	subTitle: string;
	body: string;
	files: Array<FileInfo>;
}
type PlaceHolder =
	| 'Home_NewStore'
	| 'AboutUs_StoreCount'
	| 'InvestorRelations_Details'
	| 'Social_Outreach';
type Page = 'AboutUs' | 'Home' | 'InvestorRelationPage' | 'SocialOutReach';
type Template = 'PLAIN' | 'INVESTOR' | 'RICH';

export interface GetRichContentPayload {
	contentPlaceholder: PlaceHolder;
	page: Page;
	isPublished?: boolean;
}
export interface SubCategoryInfo extends AdditionalData {
	name: string;
	sequence: number;
	isPublished: boolean;
	display?: boolean;
	files: Array<FileInfo>;
}
export interface SubMenuInfo extends AdditionalData {
	name: string;
	pageTitle: string;
	sequence: number;
	isPublished: boolean;
	subCategories: Array<SubCategoryInfo>;
}
export interface InvestorContent extends AdditionalData {
	investorCategoryName: string;
	sequence: number;
	isPublished: boolean;
	subMenus: Array<SubMenuInfo>;
}
export interface PlainContent {
	body: any;
}
export interface Content<T extends Record<string, any> = any> {
	contentId?: string;
	placeholder: PlaceHolder;
	contentTemplate: Template;
	page: Page;
	sequence: number;
	isPublished: boolean;
	content: T;
}
export interface RichContentState {
	getRichContentList: ActionState;
	richContents: Array<Content<RichContent>>;
	plainContents: Array<Content<PlainContent>>;
	investorContents: Array<Content<InvestorContent>>;
	outreachContents: Array<Content<RichContent>>;
	errorData: Partial<AjaxError>;
}

export interface GetRichContentListAction {
	type: typeof RichContentAction.GETRICHCONTENTLIST;
	payload: GetRichContentPayload;
	meta?: any;
}

export interface GetRichContentListSuccessAction {
	type: typeof RichContentAction.GETRICHCONTENTLISTSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface GetRichContentListFailureAction {
	type: typeof RichContentAction.GETRICHCONTENTLISTFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface RichContentActions {
	type: RichContentAction;
	payload?: GetRichContentPayload | AjaxResponse | AjaxError | any;
	meta?: any;
}
