import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { logger } from 'redux-logger';
import rootEpic from '../epics';
import rootReducer from '../reducers';

const epicMiddleware = createEpicMiddleware();

const { NODE_ENV = 'development' } = process.env;

type reducer = ReturnType<typeof rootReducer>;
export type RootState = reducer;
/**
 * Configure store.
 * @returns {object} store - Reducer global store.
 */
export default function configureStore() {
	const middlewares: Array<any> = [epicMiddleware];
	if (NODE_ENV === 'development') {
		middlewares.push(logger);
	}
	const store = createStore(rootReducer, applyMiddleware(...middlewares));

	epicMiddleware.run(rootEpic);
	return { store };
}

/**
 * Configure store.
 * @returns {object} store - Reducer global store.
 */
export function storeWithoutPersist() {
	const middlewares: Array<any> = [epicMiddleware];
	const store = createStore(rootReducer, applyMiddleware(...middlewares));
	epicMiddleware.run(rootEpic);
	return { store };
}
