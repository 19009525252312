import * as React from 'react';
import { ThemeProvider, CssBaseline, createMuiTheme } from '@material-ui/core';
import blue from '@material-ui/core/colors/blue';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
	dark?: boolean;
}
/**
 *
 */
class Theme extends React.PureComponent<Props> {
	/**
	 * Render method
	 * @returns {JSX.Element} Jsx.
	 */
	public render() {
		const { children, ...rest } = this.props;
		const theme = createMuiTheme({
			palette: {
				primary: {
					main: '#046d38'
				},
				secondary: blue,
				text: {
					secondary: '#000000db'
				},
				warning: {
					main: '#F7B500'
				},
				error: {
					main: '#F44336'
				},
				success: {
					main: '#6DD400'
				}
			}
		});
		return (
			<div {...rest}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					{children}
				</ThemeProvider>
			</div>
		);
	}
}

export default Theme;
