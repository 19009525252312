export enum ActionStatus {
	NONE = 'NONE',
	LOADING = 'LOADING',
	FAILURE = 'FAILURE',
	SUCCESS = 'SUCCESS',
	RETRYING = 'RETRYING'
}
export enum ActionState {
	NONE = 'NONE',
	LOADING = 'LOADING',
	FAILURE = 'FAILURE',
	SUCCESS = 'SUCCESS',
	RETRYING = 'RETRYING'
}
export const actionStatus = ActionStatus;
