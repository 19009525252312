import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import Loadable from '@loadable/component';
import { ParallaxProvider } from 'react-scroll-parallax';
import ReactGA from 'react-ga';
import Theme from './components/Theme';

import * as serviceWorker from './serviceWorker';
import CreateStore from './store';

const { REACT_APP_GA = '' } = process.env;
ReactGA.initialize(REACT_APP_GA);
const App = Loadable(() => import('./app/App'));

const { store } = CreateStore();

ReactDOM.render(
	<Provider store={store}>
		<ParallaxProvider>
			<Theme>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Theme>
		</ParallaxProvider>
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register({
// 	onSuccess: (registration) => {
// 		console.log('SW Registerd', registration);
// 	},
// 	onUpdate: (registration) => {
// 		console.log('SW Updated', registration);
// 	}
// });
serviceWorker.unregister();
