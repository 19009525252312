import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import {
	RichContentAction,
	RichContentState,
	RichContentActions,
	GetRichContentPayload
} from './types';
import { ActionState } from '../../constants/api';

const INITIAL_STATE: RichContentState = {
	getRichContentList: ActionState.NONE,
	richContents: [],
	plainContents: [],
	outreachContents: [],
	investorContents: [],
	errorData: {}
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const StoreReducer = (
	state: RichContentState = INITIAL_STATE,
	action: RichContentActions
): RichContentState => {
	switch (action.type) {
		case RichContentAction.GETRICHCONTENTLIST: {
			return {
				...state,
				getRichContentList: ActionState.LOADING
			};
		}
		case RichContentAction.GETRICHCONTENTLISTSUCCESS: {
			const { response } = action.payload as AjaxResponse;
			const { page } = action.meta.payload as GetRichContentPayload;
			return {
				...state,
				getRichContentList: ActionState.SUCCESS,
				richContents: page === 'Home' ? response : [],
				plainContents: page === 'AboutUs' ? response : [],
				investorContents: page === 'InvestorRelationPage' ? response : [],
				outreachContents: page === 'SocialOutReach' ? response : []
			};
		}
		case RichContentAction.GETRICHCONTENTLISTFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				getRichContentList: ActionState.FAILURE,
				errorData: response
			};
		}
		default:
			return state;
	}
};

export default StoreReducer;
