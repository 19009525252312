import { ofType, ActionsObservable } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { stringify } from 'query-string';
import { of } from 'rxjs/internal/observable/of';
import { CategoryActions, CategoryActionTypes } from './types';
import { GetCategorySuccess, GetCategoryFailure } from './actions';

const { REACT_APP_CATEGORY_URL = '' } = process.env;
/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
const GetCategories = (action$: ActionsObservable<CategoryActions>) =>
	action$.pipe(
		ofType(CategoryActionTypes.GETCATEGORY),
		switchMap((action) => {
			return ajax({
				method: 'GET',
				url: `${REACT_APP_CATEGORY_URL}`,
				headers: {
					'Content-Type': 'application/json'
				},
				async: true,
				crossDomain: true
			}).pipe(
				map((response: AjaxResponse) => GetCategorySuccess(response, action)),
				catchError((error: AjaxError) => of(GetCategoryFailure(error, action)))
			);
		})
	);
export default GetCategories;
