import { combineReducers } from 'redux';
import PartnerReducer from '../pages/Partner-With-Us/reducers';
import FeedbackReducer from '../pages/Feedback/reducers';
import RichContentReducer from '../redux/RichContent/reducers';
import CategoryReducer from '../redux/Category/reducers';

const Reducer = combineReducers({
	partnerFormData: PartnerReducer,
	feedbackReducer: FeedbackReducer,
	richContent: RichContentReducer,
	categories: CategoryReducer
});

export type RootState = ReturnType<typeof Reducer>;
export default Reducer;
