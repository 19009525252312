import { ofType, ActionsObservable } from 'redux-observable';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { of } from 'rxjs';
import {
	PartnerFormActions,
	SENDPARTNERDATA,
	SENDPARTNERDATAFAILURE,
	SAVEIMAGES,
	SAVEIMAGESFAILURE
} from './types';
import { SendPartnerDataSuccess, SaveImagesSuccess } from './actions';

const {
	REACT_APP_API_URL = '',
	REACT_APP_PARTNER_BASE_URL = '',
	REACT_APP_PARTNER_UPLOAD_VERB = '',
	REACT_APP_PARTNER_REGISTER_VERB = ''
} = process.env;

const sendPartnerDataURL =
	REACT_APP_API_URL + REACT_APP_PARTNER_BASE_URL + REACT_APP_PARTNER_REGISTER_VERB;
const saveImagesURL =
	REACT_APP_API_URL + REACT_APP_PARTNER_BASE_URL + REACT_APP_PARTNER_UPLOAD_VERB;

export const SendDataForPartnerEpic = (action$: ActionsObservable<PartnerFormActions>): any =>
	action$.pipe(
		ofType(SENDPARTNERDATA),
		mergeMap((action) => {
			const ActionPayload = action.payload as any;
			return ajax({
				method: 'POST',
				url: sendPartnerDataURL,
				body: JSON.stringify(ActionPayload),
				headers: { 'Content-Type': 'application/json; charset=utf-8' }
			}).pipe(
				map((response: AjaxResponse) => SendPartnerDataSuccess(response, action)),
				catchError((error: AjaxError) =>
					of({
						type: SENDPARTNERDATAFAILURE,
						payload: error,
						meta: action
					})
				)
			);
		})
	);

/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
export const SaveLandlordImages = (action$: ActionsObservable<PartnerFormActions>): any =>
	action$.pipe(
		ofType(SAVEIMAGES),
		mergeMap((action) => {
			const ActionPayload = action.payload as any;
			return ajax({
				method: 'POST',
				url: saveImagesURL,
				body: ActionPayload.images
				// headers: { 'Content-Type': 'application/json; charset=utf-8' }
			}).pipe(
				map((response: AjaxResponse) => SaveImagesSuccess(response, action)),
				catchError((error: AjaxError) =>
					of({
						type: SAVEIMAGESFAILURE,
						payload: error,
						meta: action
					})
				)
			);
		})
	);
