import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { ActionState } from '../../constants/api';

export enum CategoryActionTypes {
	GETCATEGORY = 'category/GETCATEGORY',
	GETCATEGORYSUCCESS = 'category/GETCATEGORYSUCCESS',
	GETCATEGORYFAILURE = 'category/GETCATEGORYFAILURE'
}
export interface CategoryState {
	getCategories: ActionState;
	categories: Array<any>;
	errorData: Partial<AjaxError>;
}

export interface CategoryActions {
	type: CategoryActionTypes;
	payload?: AjaxResponse | AjaxError | any;
	meta?: any;
}
