import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import {
	RichContentAction,
	GetRichContentPayload,
	GetRichContentListAction,
	GetRichContentListFailureAction,
	GetRichContentListSuccessAction
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetRichContentList = (
	payload: GetRichContentPayload,
	meta?: any
): GetRichContentListAction => {
	return {
		type: RichContentAction.GETRICHCONTENTLIST,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetRichContentListSuccess = (
	payload: AjaxResponse,
	meta?: any
): GetRichContentListSuccessAction => {
	return {
		type: RichContentAction.GETRICHCONTENTLISTSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetRichContentListFailure = (
	payload: AjaxError,
	meta?: any
): GetRichContentListFailureAction => {
	return {
		type: RichContentAction.GETRICHCONTENTLISTFAILURE,
		payload,
		meta
	};
};
