import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { ActionStatus } from '../../constants/api';

export const SENDFEEDBACKDATA = 'SENDFEEDBACKDATA';
export const SENDFEEDBACKDATASUCCESS = 'SENDFEEDBACKDATASUCCESS';
export const SENDFEEDBACKDATAFAILURE = 'SENDFEEDBACKDATAFAILURE';
export const GETSTORELIST = 'GETSTORELIST';
export const SAVEDSTORELIST = 'SAVEDSTORELIST';
export const GETSTORELISTSUCCESS = 'GETSTORELISTSUCCESS';
export const GETSTORELISTFAILURE = 'GETSTORELISTFAILURE';
export const CLEARSTATE = 'CLEARSTATE';
export const UPDATE_SITEID = 'UPDATE_SITEID';
export const VIEW_MODE = 'VIEW_MODE';

export interface FeedbackFormPayload {
	locationType: string;
	userType: string;
	state: string;
	store: string;
	city: string;
	name: string;
	emailId: string;
	mobile: string;
	addressLine1: string;
	addressLine2: string;
	pincode: string;
	comments: string;
	mode: string;
}
export interface GetRequestPayload {
	query: string;
	url: string;
}

export enum ModeTypeEnums {
	WEBSITE = 'website',
	INSTORE = 'kiosk',
	QR = 'qr'
}

export const ModeTypes = {
	website: 'WEBSITE',
	kiosk: 'INSTORE',
	qr: 'QR'
};
export interface FeedbackFormState {
	sendFeedbackDataLoading: boolean;
	sendFeedbackDataSuccess: boolean;
	sendFeedbackDataFailure: boolean;
	getStoreListLoading: boolean;
	getStoreListSuccess: boolean;
	getStoreListFailure: boolean;
	submitFeedback: ActionStatus;
	errorData: Partial<AjaxError>;
	storeList: Array<any>;
	siteId: string;
	mode: string;
	kioskMode: boolean;
}

export interface ClearStateAction {
	type: typeof CLEARSTATE;
	payload: any;
	meta?: any;
}
export interface SendFeedbackDataAction {
	type: typeof SENDFEEDBACKDATA;
	payload: any;
	meta?: any;
}

export interface SendFeedbackDataSuccessAction {
	type: typeof SENDFEEDBACKDATASUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface SendFeedbackDataFailureAction {
	type: typeof SENDFEEDBACKDATAFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface SavedStoreListAction {
	type: typeof SAVEDSTORELIST;
	payload: any;
	meta?: any;
}

export interface GetStoreListAction {
	type: typeof GETSTORELIST;
	payload: any;
	meta?: any;
}

export interface GetStoreListSuccessAction {
	type: typeof GETSTORELISTSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface GetStoreListFailureAction {
	type: typeof GETSTORELISTFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface UpdateSiteIdAction {
	type: typeof UPDATE_SITEID;
	payload: string;
	meta?: any;
}

export interface UpdateViewModeAction {
	type: typeof VIEW_MODE;
	payload: string;
	meta?: any;
}

export interface FeedbackFormActions {
	type:
		| typeof CLEARSTATE
		| typeof SENDFEEDBACKDATA
		| typeof SENDFEEDBACKDATASUCCESS
		| typeof SENDFEEDBACKDATAFAILURE
		| typeof GETSTORELIST
		| typeof GETSTORELISTSUCCESS
		| typeof SAVEDSTORELIST
		| typeof GETSTORELISTFAILURE
		| typeof UPDATE_SITEID
		| typeof VIEW_MODE;
	payload?: FeedbackFormPayload | AjaxResponse | AjaxError | any;
	meta?: any;
}
