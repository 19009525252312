import { AjaxError, AjaxResponse } from 'rxjs/ajax';
import get from 'lodash/get';
import {
	CLEARSTATE,
	FeedbackFormState,
	SENDFEEDBACKDATA,
	SENDFEEDBACKDATAFAILURE,
	SENDFEEDBACKDATASUCCESS,
	GETSTORELIST,
	GETSTORELISTFAILURE,
	GETSTORELISTSUCCESS,
	FeedbackFormActions,
	UPDATE_SITEID,
	VIEW_MODE,
	ModeTypeEnums,
	ModeTypes,
	SAVEDSTORELIST
} from './types';
import { ActionStatus } from '../../constants/api';

const INITIAL_STATE: FeedbackFormState = {
	sendFeedbackDataLoading: false,
	sendFeedbackDataSuccess: false,
	sendFeedbackDataFailure: false,
	getStoreListLoading: false,
	getStoreListSuccess: false,
	getStoreListFailure: false,
	storeList: [],
	submitFeedback: ActionStatus.NONE,
	errorData: {},
	siteId: '',
	mode: ModeTypes[ModeTypeEnums.WEBSITE],
	kioskMode: false
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const FeedbackReducer = (
	state: FeedbackFormState = INITIAL_STATE,
	action: FeedbackFormActions
): FeedbackFormState => {
	switch (action.type) {
		case CLEARSTATE: {
			return {
				...state,
				...INITIAL_STATE
			};
		}

		case SENDFEEDBACKDATA: {
			return {
				...state,
				sendFeedbackDataLoading: true,
				sendFeedbackDataSuccess: false,
				sendFeedbackDataFailure: false,
				submitFeedback: ActionStatus.LOADING
			};
		}
		case SENDFEEDBACKDATASUCCESS: {
			return {
				...state,

				sendFeedbackDataLoading: false,
				sendFeedbackDataSuccess: true,
				sendFeedbackDataFailure: false,
				submitFeedback: ActionStatus.SUCCESS
			};
		}
		case SENDFEEDBACKDATAFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,

				sendFeedbackDataLoading: false,
				sendFeedbackDataSuccess: false,
				sendFeedbackDataFailure: true,
				submitFeedback: ActionStatus.FAILURE,
				errorData: response
			};
		}
		case GETSTORELIST: {
			return {
				...state,
				getStoreListLoading: true,
				getStoreListSuccess: false,
				getStoreListFailure: false
			};
		}
		case GETSTORELISTSUCCESS: {
			const { response } = action.payload as AjaxResponse;
			return {
				...state,
				getStoreListLoading: false,
				getStoreListSuccess: true,
				getStoreListFailure: false,
				storeList: response
			};
		}
		case SAVEDSTORELIST: {
			const payLoad = action.payload;
			return {
				...state,
				getStoreListLoading: false,
				getStoreListSuccess: true,
				getStoreListFailure: false,
				storeList: payLoad
			};
		}
		case GETSTORELISTFAILURE: {
			return {
				...state,

				getStoreListLoading: false,
				getStoreListSuccess: false,
				getStoreListFailure: true
			};
		}
		case UPDATE_SITEID: {
			const siteId = action.payload;
			return { ...state, siteId, kioskMode: siteId !== '' };
		}
		case VIEW_MODE: {
			const mode = get(action, 'payload', '') === '' ? ModeTypeEnums.WEBSITE : action.payload;
			const kioskMode = [ModeTypeEnums.INSTORE, ModeTypeEnums.QR].includes(mode);
			return { ...state, mode: ModeTypes[mode], kioskMode };
		}
		default:
			return state;
	}
};

export default FeedbackReducer;
