import { combineEpics } from 'redux-observable';

import { SendDataForPartnerEpic, SaveLandlordImages } from '../pages/Partner-With-Us/epic';
import { SendFeedbackDataEpic, GetStoreListEpic } from '../pages/Feedback/epic';
import RichContentEpic from '../redux/RichContent/epic';
import GetCategories from '../redux/Category/epic';

export default combineEpics(
	SendDataForPartnerEpic,
	SaveLandlordImages,
	SendFeedbackDataEpic,
	GetStoreListEpic,
	RichContentEpic,
	GetCategories
);
