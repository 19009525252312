import { ofType, ActionsObservable } from 'redux-observable';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ajax, AjaxResponse, AjaxError } from 'rxjs/ajax';
import { stringify } from 'query-string';
import { of } from 'rxjs/internal/observable/of';
import { RichContentActions, RichContentAction } from './types';
import { GetRichContentListFailure, GetRichContentListSuccess } from './actions';

const {
	REACT_APP_API_URL = '',
	REACT_APP_CONTENT_BASE_URL = '',
	REACT_APP_CONTENT_API = ''
} = process.env;
const CONTENT_API = REACT_APP_API_URL + REACT_APP_CONTENT_BASE_URL + REACT_APP_CONTENT_API;
/**
 *  Epic
 * @param {any} action$ -  Action.
 * @param {any} state$ -  state.
 * @returns {any}  Epic.
 */
const GetStore = (action$: ActionsObservable<RichContentActions>) =>
	action$.pipe(
		ofType(RichContentAction.GETRICHCONTENTLIST),
		switchMap((action) => {
			return ajax({
				method: 'GET',
				url: `${CONTENT_API}?${stringify(action.payload)}`,
				headers: {
					'Content-Type': 'application/json'
				},
				async: true,
				crossDomain: true
			}).pipe(
				map((response: AjaxResponse) => GetRichContentListSuccess(response, action)),
				catchError((error: AjaxError) => of(GetRichContentListFailure(error, action)))
			);
		})
	);
export default GetStore;
