import { CategoryActionTypes } from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetCategory = (payload?: any, meta?: any) => {
	return {
		type: CategoryActionTypes.GETCATEGORY,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetCategorySuccess = (payload?: any, meta?: any) => {
	return {
		type: CategoryActionTypes.GETCATEGORYSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetCategoryFailure = (payload?: any, meta?: any) => {
	return {
		type: CategoryActionTypes.GETCATEGORYFAILURE,
		payload,
		meta
	};
};
