import { AjaxResponse, AjaxError } from 'rxjs/ajax';
import { CategoryState, CategoryActionTypes, CategoryActions } from './types';
import { ActionState } from '../../constants/api';

const INITIAL_STATE: CategoryState = {
	getCategories: ActionState.NONE,
	categories: [],
	errorData: {}
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const CategoryReducer = (
	state: CategoryState = INITIAL_STATE,
	action: CategoryActions
): CategoryState => {
	switch (action.type) {
		case CategoryActionTypes.GETCATEGORY: {
			return {
				...state,
				getCategories: ActionState.LOADING
			};
		}
		case CategoryActionTypes.GETCATEGORYSUCCESS: {
			const { response } = action.payload as AjaxResponse;
			return {
				...state,
				getCategories: ActionState.SUCCESS,
				categories: response
			};
		}
		case CategoryActionTypes.GETCATEGORYFAILURE: {
			const response = action.payload as AjaxError;
			return {
				...state,
				getCategories: ActionState.FAILURE,
				errorData: response
			};
		}
		default:
			return state;
	}
};

export default CategoryReducer;
