import {
	SENDFEEDBACKDATA,
	SENDFEEDBACKDATASUCCESS,
	SENDFEEDBACKDATAFAILURE,
	ClearStateAction,
	CLEARSTATE,
	SendFeedbackDataAction,
	SendFeedbackDataSuccessAction,
	SendFeedbackDataFailureAction,
	GetStoreListAction,
	GetStoreListSuccessAction,
	GetStoreListFailureAction,
	GETSTORELIST,
	GETSTORELISTSUCCESS,
	GETSTORELISTFAILURE,
	UpdateSiteIdAction,
	UPDATE_SITEID,
	VIEW_MODE,
	UpdateViewModeAction,
	SavedStoreListAction,
	SAVEDSTORELIST
} from './types';

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const SendFeedbackData = (payload: any, meta?: any): SendFeedbackDataAction => {
	return {
		type: SENDFEEDBACKDATA,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const SendFeedbackDataSuccess = (
	payload: any,
	meta?: any
): SendFeedbackDataSuccessAction => {
	return {
		type: SENDFEEDBACKDATASUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const SendFeedbackDataFailure = (
	payload: any,
	meta?: any
): SendFeedbackDataFailureAction => {
	return {
		type: SENDFEEDBACKDATAFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const ClearState = (payload: any, meta?: any): ClearStateAction => {
	return {
		type: CLEARSTATE,
		payload,
		meta
	};
};
/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */

export const SavedStoreList = (payload: any, meta?: any): SavedStoreListAction => {
	return {
		type: SAVEDSTORELIST,
		payload,
		meta
	};
};

export const GetStoreList = (payload: any, meta?: any): GetStoreListAction => {
	return {
		type: GETSTORELIST,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetStoreListSuccess = (payload: any, meta?: any): GetStoreListSuccessAction => {
	return {
		type: GETSTORELISTSUCCESS,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const GetStoreListFailure = (payload: any, meta?: any): GetStoreListFailureAction => {
	return {
		type: GETSTORELISTFAILURE,
		payload,
		meta
	};
};

/**
 * Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UpdateSiteId = (payload: string, meta?: any): UpdateSiteIdAction => {
	return {
		type: UPDATE_SITEID,
		payload,
		meta
	};
};

/**
 * Update ViewMode Action.
 * @param {any} payload -  Payload.
 * @param {any} meta -  Action meta.
 * @returns {any}  Action.
 */
export const UpdateViewMode = (payload: string, meta?: any): UpdateViewModeAction => {
	return {
		type: VIEW_MODE,
		payload,
		meta
	};
};
