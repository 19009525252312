import { AjaxResponse, AjaxError } from 'rxjs/ajax';

export const SENDPARTNERDATA = 'SENDPARTNERDATA';
export const SENDPARTNERDATASUCCESS = 'SENDPARTNERDATASUCCESS';
export const SENDPARTNERDATAFAILURE = 'SENDPARTNERDATAFAILURE';
export const SAVEIMAGES = 'SAVEIMAGES';
export const SAVEIMAGESSUCCESS = 'SAVEIMAGESSUCCESS';
export const SAVEIMAGESFAILURE = 'SAVEIMAGESFAILURE';

export const CLEARSTATE = 'CLEARSTATE';

export interface ImageUploadPayload {
	image1: any;
	image2: any;
	image3: any;
}
export interface PartnerDataPayload {
	partnerType: string;
	'g-recaptcha-response': string;
	status: string;
	createdOn: string;
	modifiedOn: string;
	supplier: any;
	landlord: any;
}
export interface LandlordPartnerDataPayload {
	company: string;
	website: string;
	firstname: string;
	lastName: string;
	emailId: string;
	mobile: string;
	telno: string;
	state: string;
	city: string;
	location: string;
	landmark: string;
	address: string;
	landlordType: string;
	landAddress: string;
	landlocation: string;
	plotSize: string;
	frontage: string;
	landLength: string;
	landWidth: string;
	carpetArea: string;
	floorArea: string;
	commercialArea: string;
	roadWidth: string;
	roadSideWidth: string;
	serviceRoads: string;
	propertyType: string;
	dayTimeStreetTraffic: string;
	nightTimeStreetTraffic: string;
	catchment: string;
	developerName: string;
	majorResidentialProjects: string;
	commercialProjectsDone: string;
	presence: string;
	formData: FormData;
}

export interface PartnerFormState {
	sendPartnerDataLoading: boolean;
	sendPartnerDataSuccess: boolean;
	sendPartnerDataFailure: boolean;
	SaveImagesLoading: boolean;
	SaveImagesSuccess: boolean;
	SaveImagesFailure: boolean;
	fileIds: Array<string>;
}

export interface ClearStateAction {
	type: typeof CLEARSTATE;
	payload: any;
	meta?: any;
}
export interface SendPartnerDataAction {
	type: typeof SENDPARTNERDATA;
	payload: PartnerDataPayload;
	meta?: any;
}

export interface SendPartnerDataSuccessAction {
	type: typeof SENDPARTNERDATASUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface SendPartnerDataFailureAction {
	type: typeof SENDPARTNERDATAFAILURE;
	payload: AjaxError;
	meta?: any;
}
export interface SaveImagesAction {
	type: typeof SAVEIMAGES;
	payload: PartnerDataPayload;
	meta?: any;
}

export interface SaveImagesSuccessAction {
	type: typeof SAVEIMAGESSUCCESS;
	payload: AjaxResponse;
	meta?: any;
}

export interface SaveImagesFailureAction {
	type: typeof SAVEIMAGESFAILURE;
	payload: AjaxError;
	meta?: any;
}

export interface PartnerFormActions {
	type:
		| typeof SENDPARTNERDATA
		| typeof CLEARSTATE
		| typeof SENDPARTNERDATASUCCESS
		| typeof SENDPARTNERDATAFAILURE
		| typeof SAVEIMAGES
		| typeof SAVEIMAGESSUCCESS
		| typeof SAVEIMAGESFAILURE;

	payload?: LandlordPartnerDataPayload | AjaxResponse | AjaxError | any;
	meta?: any;
}
