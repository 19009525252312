import { AjaxResponse } from 'rxjs/ajax';
import {
	CLEARSTATE,
	PartnerFormState,
	SENDPARTNERDATA,
	SENDPARTNERDATAFAILURE,
	SENDPARTNERDATASUCCESS,
	SAVEIMAGES,
	SAVEIMAGESFAILURE,
	SAVEIMAGESSUCCESS,
	PartnerFormActions
} from './types';

const INITIAL_STATE: PartnerFormState = {
	sendPartnerDataLoading: false,
	sendPartnerDataSuccess: false,
	sendPartnerDataFailure: false,
	SaveImagesLoading: false,
	SaveImagesSuccess: false,
	SaveImagesFailure: false,
	fileIds: []
};
/**
 * Reducer.
 * @param {any} state -  state.
 * @param {any} action - One of actions
 * @returns {any}  state object.
 */
const PartnerReducer = (
	state: PartnerFormState = INITIAL_STATE,
	action: PartnerFormActions
): PartnerFormState => {
	switch (action.type) {
		case CLEARSTATE: {
			return {
				...state,
				sendPartnerDataLoading: false,
				sendPartnerDataSuccess: false,
				sendPartnerDataFailure: false
			};
		}

		case SENDPARTNERDATA: {
			return {
				...state,

				sendPartnerDataLoading: true,
				sendPartnerDataSuccess: false,
				sendPartnerDataFailure: false
			};
		}
		case SENDPARTNERDATASUCCESS: {
			return {
				...state,

				sendPartnerDataLoading: false,
				sendPartnerDataSuccess: true,
				sendPartnerDataFailure: false
			};
		}
		case SENDPARTNERDATAFAILURE: {
			return {
				...state,

				sendPartnerDataLoading: false,
				sendPartnerDataSuccess: false,
				sendPartnerDataFailure: true
			};
		}
		case SAVEIMAGES: {
			return {
				...state,

				SaveImagesLoading: true,
				SaveImagesSuccess: false,
				SaveImagesFailure: false
			};
		}
		case SAVEIMAGESSUCCESS: {
			const { response } = action.payload as AjaxResponse;
			return {
				...state,
				fileIds: response,
				SaveImagesLoading: false,
				SaveImagesSuccess: true,
				SaveImagesFailure: false
			};
		}
		case SAVEIMAGESFAILURE: {
			return {
				...state,
				SaveImagesLoading: false,
				SaveImagesSuccess: false,
				SaveImagesFailure: true
			};
		}

		default:
			return state;
	}
};

export default PartnerReducer;
// export default createReducer(TripSummaryReducer, {
//     name: 'tripSummary',
//     password: 'trpsmry_password',
//     applicationName: 'HDO',
// })
